import React, { useState,useEffect } from 'react';
import Cookies from 'js-cookie'
import './styles.css';
import Header from '../../components/Header';
import api from '../../services/api';
import { FaUndo, FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AccessSkeleton from '../../components/AccessSkeleton';
import { format, parseISO } from 'date-fns';
import Pagination from '@material-ui/lab/Pagination';

export default function FinishedReview(){
    const token = Cookies.get('token');
    const [finishedAccesses, setFinishedAccesses ] = useState([]);
    const [loading, setLoading ] = useState(true);
    const [page, setPage ] = useState(1);
    const [totalPages, setTotalPage ] = useState(1);
    const [totalFinished, setTotalFinished ] = useState(0);
    const [filteredApp, setFilteredApp ] = useState('');
    const [filteredRole, setFilteredRole ] = useState('');
    const [searchAccess, setSearchAccess ] = useState('');
    const [appOptions, setAppOptions ] = useState([]);
    const [roleOptions, setRoleOptions ] = useState([]);
    const [loadingBatch, setLoadingBatch ] = useState(false);
    const [checkedOptions, setCheckedOptions ] = useState([]);
    const [orderBy, setOrderBy ] = useState('');
    const [orderType, setOrderType ] = useState('asc');
    const [orderAsc, setOrderAsc ] = useState(true);
    const [accessesTotal, setAccessesTotal ] = useState([]);

    function handleChange(e, value) {
        setPage(value);            
    };

    useEffect(() => {
        try {
            api.get(`revisar?showPending=2&page=${page}&app=${filteredApp}&role=${filteredRole}&search=${searchAccess}&orderBy=${orderBy}&orderType=${orderType}`).then(response => {
                setFinishedAccesses(response.data.accesses);
                setTotalPage(Math.ceil(response.data.count.count/500))
                setTotalFinished(response.data.total.length)
                setAccessesTotal(response.data.total)
                setLoading(false)
            }).catch(err => console.log(err)); 
        } catch (error) {
            if(error.response.status !== 401){
                alert('Erro ao acessar.')
            }
        }
    }, [token, page, filteredApp, filteredRole, searchAccess, orderBy, orderType]);

    useEffect(() => {
        try {
            api.get(`ferramentas`).then(response => {
                setAppOptions(response.data)
            }).catch(err => console.log(err)); 
        } catch (error) {
            if(error.response.status !== 401){
                alert('Erro ao acessar.')
            }
        }

    }, [token])

    useEffect(() => {
        let apps_ids=[];

        if (!filteredApp) {
            apps_ids=appOptions.map(app => {return app.id});
        }

        try {
            api.get(`permissoes?apps_ids=${apps_ids}&filtered_app=${filteredApp}&reviewer_accesses=true`).then(response => {
                setRoleOptions(response.data)
            }).catch(err => console.log(err)); 
        } catch (error) {
            if(error.response.status !== 401){
                alert('Erro ao acessar.')
            }
        }

    }, [appOptions, filteredApp, accessesTotal])

    async function handleUpdateAccess(id){
        const data = {
            revised_at: null,
            is_valid: true,
            justification_id: null
        }

        try {
            const response = await api.put(`revisar/${id}`, data);

            if(response.status === 200){
                setFinishedAccesses(finishedAccesses.filter(access => access.id !== id));
                setTotalFinished(totalFinished-1)
            }
        } catch (error) {
            if(error.response.status !== 401){
                if(error.response?.data?.error){
                    alert(error.response.data.error);
                }else{
                    alert('Erro revisar acesso, tente novamente');
                }
            }
        }
    }

    async function handleBatchUpdateAccess(ids){
        setLoadingBatch(true);
        const idsLength = ids.length;

        const data = {
            revised_at: null,
            is_valid: true,
            justification_id: null,
            accesses_ids: ids
        }

        try {
            await api.put('revisar_lote', data);
            
            setLoadingBatch(false);
            setCheckedOptions([]);
            setTotalFinished(totalFinished-idsLength)
            Array.from(document.querySelectorAll(".header-check"))
            .forEach((checkbox) => {
                checkbox.checked = false;
            });
            if(idsLength === 500 && page < totalPages){
                setPage(page+1);
            }else{
                const newAccesses = finishedAccesses.filter(access => !ids.map(Number).includes(access.id))
                setFinishedAccesses(newAccesses);
            }
        } catch (error) {
            setLoadingBatch(false);

            if(error.response?.status !== 401){
                if(error.response?.data?.error){
                    alert(error.response.data.error);
                }else{
                    alert('Erro revisar acesso, tente novamente');
                }
            }
        }
    }

    async function handleCheckAll(e){
        if(e.target.checked){
            const checkAll = await finishedAccesses.map(access => { return access.id.toString() });
            setCheckedOptions(checkAll);
            Array.from(document.querySelectorAll(".check-access"))
            .forEach((checkbox) => {
                checkbox.checked = true;
            });

        }else{
            setCheckedOptions([]);
            Array.from(document.querySelectorAll(".check-access"))
            .forEach((checkbox) => {
                checkbox.checked = false;
            });
        }
    }

    function handleCheck(e){
        if(e.target.checked){
            const addNewCheck = [...checkedOptions, e.target.name];
            setCheckedOptions(addNewCheck)
        }else{
            const removeNewCheck = checkedOptions.filter(option => (option !== e.target.name));
            setCheckedOptions(removeNewCheck);
        }
    }

    async function handleOrder(collumn){
        setOrderBy(collumn)
        setOrderAsc(!orderAsc)

        if(!orderAsc){
            setOrderType('desc')
        }else{
            setOrderType('asc')
        }
    }

    return(
        <div className="finished-review-container">
            <Header />
            <main>
                <div className="review-options">
                    <Link className="button review-options-review-logs-button" to="/periodos_revisao">
                        Períodos de revisão
                    </Link>
                    <Link className="button review-options-pending-button" to="/revisar">
                        Revisões Pendentes
                    </Link>
                    <Link className="button review-options-finished-button" to="/revisadas">
                        {totalFinished} Revisões Finalizadas
                    </Link>
                </div>
                {totalFinished > 0 && <div className="finished-review-filters">
                    <select name="filtered-tool" id="filtered-tool" onChange={e => setFilteredApp(e.target.value)}>
                        <option key='0' value=''>- Selecione uma ferramenta -</option>
                        {appOptions.map(app => (
                            <option key={app.id} value={app.id}>{app.name}</option>
                        ))}
                    </select>
                    <select name="filtered-role" id="filtered-role" onChange={e => setFilteredRole(e.target.value)}>
                        <option key='0' value=''>- Selecione uma permissão -</option>
                        {roleOptions.map(role => (
                            <option key={role.id} value={role.id}>{role.name}</option>
                        ))}
                    </select>
                    <input type="text" name="search-access" placeholder="Pesquise um e-mail" onChange={e => setSearchAccess(e.target.value)} />
                </div>}
                {checkedOptions && checkedOptions.length > 0 && (<div className="batch-actions">
                    {loadingBatch && <button className="button" onClick={() => {}} disabled>
                        Carregando...
                    </button>}
                    {!loadingBatch && <button className="confirm-button button" onClick={() => handleBatchUpdateAccess(checkedOptions)}>
                        Refazer revisão dos selecionados
                    </button>}
                </div>)}
                {loading && <AccessSkeleton />}
                {!loading && <div className="access-container">
                        <div className="access-header">
                            {<input type="checkbox" className="header-check" onChange={e => handleCheckAll(e)} />}
                            <p className="header-employee" onClick={e => handleOrder('user_name')}>
                                <span className="header-title">Colaborador</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-app" onClick={e => handleOrder('apps.name')}>
                                <span className="header-title">Ferramenta</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-role" onClick={e => handleOrder('accesses_roles.name')}>
                                <span className="header-title">Permissão</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-created" onClick={e => handleOrder('user_created_at')}>
                                <span className="header-title">Criado em</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-last-login" onClick={e => handleOrder('last_login')}>
                                <span className="header-title">Último login</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-actions">Ações</p>
                        </div>
                    {finishedAccesses.length === 0 && <h2>0 resultados encontrados</h2>}
                    {finishedAccesses.map((access, index) => (
                        <div className="access" key={access.id}>
                           {<input type="checkbox" className="check-access" name={access.id} onChange={e => handleCheck(e)} />}
                            <div className="access-info">
                                    <h2 className="access-name">{access.user_name?access.user_name:'-'}</h2>
                                    {access.user_mail?.length > 1 && (<p>{access.user_mail}</p>)}
                                    {access.user_primary_key && access.user_primary_key !==access.user_mail && (<p>{access.user_primary_key}</p>)}
                            </div>
                            <p className="access-tool">{access.app}</p>
                            <p className="access-role">{access.role}</p>
                            <p className="access-created">{access.user_created_at?format(parseISO(access.user_created_at), 'dd/MM/yyyy'):'Não informado'}</p>
                            <p className="access-last-login">{access.last_login?format(parseISO(access.last_login), 'dd/MM/yyyy'):'Não informado'}</p>
                            <div className="access-actions">
                                <div className="access-actions-container">
                                    <div className="access-status-container">
                                        <div className="access-status">
                                            Revisão: <strong>{access.is_valid?'Manter':'Excluir'}</strong>
                                        </div>                                    
                                    </div>
                                    {<button className="undo-delete-button button" onClick={() => handleUpdateAccess(access.id)}>
                                        <FaUndo />
                                    </button>}
                                </div>
                                {access.justification_id && 
                                    (<div className="access-justification"><strong>Justificativa:&nbsp;</strong>
                                     {access.justification_id === 1 && 'Não necessita mais do acesso para exercer suas atividades'}
                                     {access.justification_id === 2 && 'Profissional desligado/contrato encerrado'}
                                     {access.justification_id === 3 && 'Descontinuidade de serviço'}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    <div>
                        <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
                    </div>
                </div>}
            </main>
        </div>
    )
}