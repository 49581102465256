import React, {useEffect, useState} from 'react';
import './styles.css';
import api from '../../services/api';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import AccessSkeleton from '../../components/AccessSkeleton';
import { format, parseISO, add } from 'date-fns';
import { Link } from 'react-router-dom';

export default function ReviewPeriods(){
    const [periods, setPeriods ] = useState([]);
    const [loading, setLoading ] = useState(true);

    useEffect(() => {
        try {
            api.get('periodo').then(response => {
                const period = response.data;
                period.start_date = add(parseISO(period.start_date), {'hours': 4 });
                period.end_date = add(parseISO(period.end_date), {'hours': 4 });
                console.log("period", period)

                setPeriods(period);
                setLoading(false)
            }).catch(err => console.log(err)); 
        } catch (error) {
            alert('Erro ao acessar.')
        }
    }, []);

    return(
        <div className="review-periods-container">
            <Header />
            <div className="review-periods-menu-container">
                <Menu />
                <main>
                    <div className="review-periods-info">
                        <div className="review-periods-content">
                            <h1>Períodos de revisão</h1>
                            <p>Durante os períodos cadastrados, os usuários conseguirão fazer a revisão dos acessos</p>
                        </div>
                        <div className="review-periods-actions">
                            <Link className="button create-review-period-button" to="/periodos/create">
                                Novo
                            </Link>
                        </div>
                    </div>
                    {loading && <AccessSkeleton />}
                    {!loading && <div className="periods-lists">
                        <div className="periods-header">
                            <p className="header-start-date">Data inicial</p>
                            <p className="header-end-date">Data final</p>
                            <p className="header-status">Status</p>
                        </div>
                        {/* {periods.map((period, index) => ( */}
                            <div className="period" key={periods.id}>
                                <p className="period-start-date">{format(periods.start_date, 'dd/MM/yyyy') ??'-'}</p>
                                <p className="period-end-date">{format(periods.end_date, 'dd/MM/yyyy') ?? '-'}</p>
                                <p className="period-is-active">{periods.is_active ? 'Ativo' : 'Inativo' }</p>
                            </div>
                        {/* ))} */}
                    
                    </div>}
                </main>
            </div>
        </div>
    )
}