import React, {useState} from 'react';
import './styles-create.css';
import api from '../../services/api';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import {useHistory} from 'react-router-dom';

export default function ReviewPeriodsCreate(){
    const [start_date, setStartDate ] = useState('');
    const [end_date, setEndDate ] = useState('');
    const history = useHistory();

    async function handleCreatePeriod(e){
        e.preventDefault();
        const data = {
            start_date,
            end_date
        }

        try {
            const response = await api.post('periodo', data);
            console.log("response", response)
            
            if(response){
                history.push('/periodos');
            }
        } catch (error) {
            alert('Erro ao criar período, tente novamente');
        }
    }

    return(
        <div className="period-create-container">
            <Header />
            <div className="period-create-menu-container">
                <Menu />
                <main>
                    <div className="period-create-info">
                        <h1>Períodos</h1>
                        <p>Criar um novo período</p>
                    </div>
                    <form onSubmit={e => handleCreatePeriod(e)}>
                        <input 
                            value={start_date}
                            type="date"
                            onChange={e => setStartDate(e.target.value)}
                            placeholder="Data de início" />
                        <input 
                            value={end_date}
                            type="date"
                            onChange={e => setEndDate(e.target.value)}
                            placeholder="Data fim" />
                        <button type="submit" className="button">
                            Criar período
                        </button>
                    </form>
                </main>
            </div>
        </div>
    )
}