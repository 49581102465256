import Cookies from 'js-cookie';
import decode from 'jwt-decode';

import { ROLE_ADMINISTRATOR } from '../constants/index'

export const isAuthorized = (role) =>  {
    const token = Cookies.get('token');

    if(!token){
        return false;
    }

    if(role === ROLE_ADMINISTRATOR){
        const decodeToken = decode(token);

        if(!decodeToken.roles){
            return false;
        }

        const roleIsValid =  decodeToken.roles.includes(role);

        if(!roleIsValid){
            return false;
        }
    }

    return true;
}