import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';

export default function Menu(){
    return(
        <div className="menu-container">
            <div className="menu-options">
                <Link to="/dashboard" className="menu">Overview</Link>
                <Link to="/revisao_ferramentas" className="menu">Revisão por ferramentas</Link>
                <Link to="/revisao_gestores" className="menu">Revisão por gestor</Link>
                <Link to="/revisao_acessos" className="menu">Revisão por acessos</Link>
                <Link to="/arquivos" className="menu">Bases</Link>
                <Link to="/periodos" className="menu">Períodos de revisão</Link>
                <Link to="/revisar" className="menu">Revisar</Link>
            </div>
        </div>
    )
}