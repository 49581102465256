import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { uniqueId } from 'lodash';
import './styles.css';
import api from '../../services/api';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import AccessSkeleton from '../../components/AccessSkeleton';
import { FaCloudDownloadAlt, FaTrash, FaKey } from 'react-icons/fa';


export default function ToolsList() {
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [toolOptions, setToolOptions] = useState([]);
  const [filteredTool, setFilteredTool] = useState('');
  const [totalApps, setTotalApps] = useState([]);

  useEffect(() => {
    getTools()
        .then(() => {})
        .catch((error) => {
          console.log(error);
        })
  }, []);

  useEffect(() => {
    const filteredToolFound = toolOptions.find((tool) => tool.name.toLowerCase() === filteredTool.toLowerCase());
    if (!filteredToolFound && filteredTool) {
      return;
    }

    getTools(filteredToolFound?.id || null)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        })
  }, [filteredTool]);

  const getTools = (toolId=null) => {
    return new Promise((resolve, reject) => {
      const baseUrl = '/acessos_ferramentas'
      const url = toolId ? `${ baseUrl }?app_id=${ toolId }` : baseUrl;
      setLoading(true);

      api.get(url).then((response) => {
        setApps(response.data);
        setTotalApps(response.data.review_logs);
        setLoading(false);
        resolve(true);
      }).catch((error) => {
        reject(error);
      });
    })
  }

  function total_accesses(aplication) {
    return (
      apps.total_accesses.find((a) => a.app === aplication.app)?.total || 0
    );
  }

  function pending_accesses(aplication) {
    return (
      apps.pending_accesses.find((a) => a.app === aplication.app)?.total || 0
    );
  }

  function maintained_accesses(aplication) {
    return (
      apps.maintained_accesses.find((a) => a.app === aplication.app)?.total || 0
    );
  }

  function excluded_accesses(aplication, justification_id) {
    return (
      apps.excluded_accesses.find(
        (a) =>
          a.app === aplication.app && a.justification_id === justification_id
      )?.total || 0
    );
  }

  async function handleExport(aplication) {
      try {
        await api.post(`arquivos?app_id=${aplication.app_id}&&app_name=${aplication.app}`);
        history.push('/arquivos');
      } catch (error) {
        alert('Erro ao acessar.');
      }
  }

  async function handleDelete(aplication) {
    const isDeleteConfirmed = window.confirm(`Tem certeza que deseja excluir os acessos de ${aplication.app}?`);

    if (isDeleteConfirmed){
        await api.delete(`/acessos_ferramentas/${aplication.app_id}`);
        setTotalApps(totalApps.filter(app => app.app !== aplication.app))
    }
  }

  async function handleOpenReview(aplication) {
        const isOpenReviewConfirmed = window.confirm(`Tem certeza que deseja reabrir a revisão ${aplication.app} de ${aplication.reviewer_email}?`);
    
        if (isOpenReviewConfirmed){
            await api.put(`/log_revisao_atualizar/${aplication.reviewer_email}/${aplication.app_id}`);
        }
    }

  useEffect(() => {
    try {
      api
        .get(`ferramentas?current_user=false`)
        .then((response) => {
          setToolOptions(response.data);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="tools-list-container">
      <Header />
      <div className="tools-list-menu-container">
        <Menu />
        <main>
          <div className="filters">
            <input
              name="filtered-tool"
              id="filtered-tool"
              list="tools"
              placeholder="Digite o nome da ferramenta ou clique para selecionar"
              onKeyDown={(e) => setFilteredTool(e.target.value)}
              onChange={(e) => setFilteredTool(e.target.value)}
            >
            </input>
            <datalist id="tools">
              {toolOptions.map((app) => (
                  <option key={app.id} value={app.name}>
                    {app.name}
                  </option>
              ))}
            </datalist>
          </div>
          {loading && <AccessSkeleton />}
          {!loading && (
            <>
              <div className="apps-header">
                <p>Id</p>
                <p>App</p>
                <p>Revisões totais</p>
                <p>Revisões pendentes</p>
                <p>Revisões finalizadas</p>
                <p>Revisor</p>
                <p>Data início</p>
                <p>Data fim</p>
                <p>Acessos Mantidos</p>
                <p>Acessos para excluir</p>
                <p>Ação</p>
              </div>
              {totalApps.map((aplication, index) => (
                <div className="access" key={ uniqueId() }>
                  <p>{aplication.app_id}</p>
                  <p>{aplication.app}</p>
                  <p>{total_accesses(aplication)}</p>
                  <p>{pending_accesses(aplication)}</p>
                  <p>{total_accesses(aplication) - pending_accesses(aplication)}</p>
                  <p>{aplication.reviewer_email}</p>
                  <p>{aplication.start_date?format(parseISO(aplication.start_date), 'dd/MM/yyyy HH:mm'):'Não iniciado'}</p>
                  <p>{aplication.end_date?format(parseISO(aplication.end_date), 'dd/MM/yyyy HH:mm'):'-'}</p>
                  <p>
                    {maintained_accesses(aplication)}
                  </p>
                  <p>
                    <span>
                      Não necessário: <b>{excluded_accesses(aplication, 1)}</b>
                    </span>
                    <br />
                    <span>
                      Desligado: <b>{excluded_accesses(aplication, 2)}</b>
                    </span>
                    <br />
                    <span>
                      Descontinuidade:<b>{excluded_accesses(aplication, 3)}</b>
                    </span>
                  </p>
                  <p className="actions_column">
                    <FaCloudDownloadAlt
                      className="download"
                      onClick={() => handleExport(aplication)}
                      />
                    {aplication.end_date &&<FaKey
                                        className="open"
                                        onClick={() => handleOpenReview(aplication)}
                                        />}
                    <FaTrash
                      className="delete"
                      onClick={() => handleDelete(aplication)}
                    />
                  </p>
                </div>
              ))}
            </>
          )}
        </main>
      </div>
    </div>
  );
}
