import React, { useContext, useEffect } from 'react';
import { UserContext } from "../../contexts/UserProvider";

export default function Callback(props){
    const { signIn } = useContext(UserContext);

    useEffect(() => {
        const user = new URLSearchParams(props.location.search);
        const permission = user.get('permission')
        const roles = user.get('roles')
        const token = user.get('token')

        signIn(token, permission, roles)
        // eslint-disable-next-line
    }, [])

    return(
        <div className="callback">
            <h1>Logando...</h1>
        </div>
    )
}