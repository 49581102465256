import React, {useState} from 'react';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import { uniqueId } from 'lodash';
import './styles.css';
import api from '../../services/api';
import Header from '../../components/Header';
import Upload from '../../components/Upload';
import FileList from '../../components/FileList';
import Menu from '../../components/Menu';


export default function UploadData(){
    const [uploadedFiles, setUploadedFiles ] = useState([]);
    const [uploadedEmployeesFiles, setUploadedEmployeesFiles ] = useState([]);
    const [uploadedDefaultRolesFiles, setUploadedDefaultRolesFiles ] = useState([]);

    const handleUpload = async (files) => {
        const newUploadedFiles = files.map(file => ({
            file,
            id: uniqueId(),
            key: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
            errors: 0,
            success: 0
        }));

        let newFiles = uploadedFiles.concat(newUploadedFiles)

        async function processUpload(processFile, type=1) {
            const data = new FormData();
            data.append('file', processFile.file);
            let url;
    
            if(type === 2){
                url = '/funcionarios'
            } else if(type === 3){
                url = '/permissoes_padroes'
            } else{
                url = '/acessos'
            }

            try {
                const response = await api.post(url, data, {
                    onUploadProgress: e => {
                        const progress = parseInt(Math.round((e.loaded * 100) / e.total));
                        updateFile(processFile.id, {
                            ...processFile, progress
                        }, type)
                    },
                    headers: {
                        ContentType: 'multipart/form-data'
                    }
                });

                if (!response || response.status !== 200) {
                    console.log("error response", response)
                    throw Error('Erro não esperado, favor checar o arquivo e tentar novamente mais tarde!');
                }

                const { message, errors, success } = response.data;
                await updateFile(processFile.id, {
                    ...processFile,
                    uploaded: true,
                    errors,
                    success,
                }, type);
                toast.success(`${ processFile.name } - ${ message }`);
            } catch (error) {
                const errorMessage = error?.message ||
                    'Houve um erro inesperado, por favor tente mais tarde!'
                toast.error(errorMessage);
                await updateFile(
                    processFile.id,
                    { ...processFile, error: true },
                    type
                );
            }
        } 

        async function updateFile(id, data, type = 1) {
            if (type === 2) {
                const files = [...uploadedEmployeesFiles.filter((file) => file.id !== id), data];
                setUploadedEmployeesFiles(files);
            } else if (type === 3) {
                const files = [...uploadedDefaultRolesFiles.filter((file) => file.id !== id), data];
                setUploadedDefaultRolesFiles(files);
            } else {
                const old = newFiles.filter((file) => file.id !== id)
                newFiles = [...old, data];
                setUploadedFiles(newFiles);
            }
        }


        for(let file of newUploadedFiles) {
            await processUpload(file, 1)
        }

        await api.post('/base_publica')

    };

    // const handleUploadEmployees = (files) => {
    //     const newUploadedEmployeesFiles = files.map(file => ({
    //         file,
    //         id: uniqueId(),
    //         name: file.name,
    //         readableSize: filesize(file.size),
    //         progress: 0,
    //         uploaded: false,
    //         error: false,
    //         url: null,
    //         errors: 0,
    //         success: 0
    //     }));

    //     setUploadedEmployeesFiles(uploadedEmployeesFiles.concat(newUploadedEmployeesFiles));

    //     newUploadedEmployeesFiles.forEach((file) => processUpload(file, 2));
    // };

    // const handleUploadDefaultRoles = (files) => {
    //     const newUploadedDefaultRolesFiles = files.map(file => ({
    //         file,
    //         id: uniqueId(),
    //         name: file.name,
    //         readableSize: filesize(file.size),
    //         progress: 0,
    //         uploaded: false,
    //         error: false,
    //         url: null,
    //         errors: 0,
    //         success: 0
    //     }));

    //     setUploadedDefaultRolesFiles(uploadedDefaultRolesFiles.concat(newUploadedDefaultRolesFiles));

    //     newUploadedDefaultRolesFiles.forEach((file) => processUpload(file, 3));
    // };

      
    return(
        <div className="upload-data-container">
            <Header />
            <div className="upload-data-menu-container">
                <Menu />
                <main>
                        <div className="accesses-upload-container">
                            <h1>Upload de acessos</h1>
                            <div className="file-content">
                                <Upload OnUpload={handleUpload} />
                                { !!uploadedFiles.length && (<FileList files={uploadedFiles} />) }
                            </div>
                        </div>
                        {/* <div className="employees-upload-container">
                            <h1>Upload de funcionários</h1>
                            <div className="file-content">
                                <Upload OnUpload={handleUploadEmployees} />
                                { !!uploadedEmployeesFiles.length && (<FileList files={uploadedEmployeesFiles} />) }
                            </div>
                        </div> */}
                        {/* <div className="default-roles-upload-container">
                            <h1>Upload de acesso default</h1>
                            <div className="file-content">
                                <Upload OnUpload={handleUploadDefaultRoles} />
                                { !!uploadedDefaultRolesFiles.length && (<FileList files={uploadedDefaultRolesFiles} />) }
                            </div>
                        </div> */}
                </main>
            </div>
        </div>
    )
}