import React, {useEffect, useState} from 'react';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { FaFileDownload } from 'react-icons/fa';
import Fuse from "fuse.js";
import {toast} from "react-toastify";

import './styles.css';
import api from '../../services/api';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import AccessSkeleton from '../../components/AccessSkeleton';

export default function Files(){
    let fuseInstance = null;
    const [backupFiles, setBackupFiles] = useState([]);
    const [files, setFiles ] = useState([]);
    const [loading, setLoading ] = useState(true);
    const [loadingExport, setLoadingExport ] = useState(false);
    const [hasFileSelected, setHasFileSelected] = useState(false);
    const [loadingDownloads, setLoadingDownloads] = useState(false);
    const [searchFile, setSearchFile] = useState('');

    useEffect(() => {
        try {
            api.get('arquivos').then(response => {
                const filesNormalized = response.data.map((file) => {
                    return {
                        ...file,
                        checked: false
                    }
                });

                setFiles(filesNormalized);
                setBackupFiles(filesNormalized)
                setLoading(false)
            }).catch(err => console.log(err)); 
        } catch (error) {
            console.error(error);
            toast.error('Erro desconhecido ao listar arquivos, tente novamente mais tarde!');
        }
    }, []);

    useEffect(() => {
        if (!backupFiles) return;
        if (!searchFile && files.length !== backupFiles.length) {
            return setFiles(backupFiles);
        }

        const fuseInstance = getFuseInstance();
        if (!fuseInstance) return;

        const searchResult = fuseInstance.search(searchFile);
        setFiles(searchResult.map((result) => {
            return {
                ...result.item
            }
        }));
    }, [searchFile]);

    function getFuseInstance() {
        if (!backupFiles) {
            console.warn('was not possible create Fuse instance because files list has not arrived yet');
            return null;
        }

        if (!fuseInstance) {
            fuseInstance = new Fuse(
                backupFiles,
                {
                    shouldSort: true,
                    includeScore: true,
                    ignoreLocation: true,
                    threshold: 0.5,
                    keys: ['name']
                }
            );
        }

        return fuseInstance;
    }

    async function handleExport(){
        setLoadingExport(true)
        const toastId = toast.loading(
            `Aguarde um instante enquanto preparamos os arquivos para realizar a exportação!`
        );

        try {
            const response = await api.post('arquivos');
            toast.update(
                toastId,
                {
                    render: `Exportação realizada com sucesso!`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000
                }
            )

            const newFile = {
                id: response.data.hash,
                name: response.data.name,
                type: 'output',
                created_at: '',
                link: response.data.link,
                hash: response.data.hash
            }

            const newFiles = [...files, newFile];
            setFiles(newFiles);
            setLoadingExport(false);
        } catch(error) {
            console.error(error);
            setLoadingExport(false)
            toast.update(
                toastId,
                {
                    render: 'Houve um erro ao tentar fazer a exportação dos arquivos!',
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000
                }
            )
        }
    }

    async function handleDownload(link, type) {
        console.log("link", link, link.toString());
        const [, fileName] = link.split(`${ type }/`)
        const toastId = toast.loading(
            `Aguarde um instante enquanto preparamos o arquivo ${ fileName } para o download!`
        );

        try {
            const response = await api.get(
                `exporta_acessos?link=${link.toString()}`,
                { responseType: 'blob' }
            );

            toast.update(
                toastId,
                {
                    render: `Seu arquivo ${ fileName } já pode ser baixado!`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000
                }
            )

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
        } catch(error) {
            console.error(error);
            toast.update(
                toastId,
                {
                    render: 'Houve um erro ao tentar fazer o download do arquivo: ' + fileName,
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000
                }
            )
        }
    }

    function handleAllDownloads() {
        setLoadingDownloads(true);
        const toastId = toast.loading(
            `Aguarde um instante enquanto preparamos os arquivos selecionados para o download!`
        );

        const filesToDownload = files.filter((fileAux) => fileAux.checked);
        const promises = filesToDownload.map((fileToDownload) => {
            return handleDownload(
                fileToDownload.link,
                fileToDownload.type
            )
        });

        Promise.all(promises).then((result) => {
            console.log("result", result);
            toast.update(
                toastId,
                {
                    render: `Arquivos baixados com sucesso!`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000
                }
            )

            setFiles(files.map((file) => {
                return { ...file, checked: false }
            }));
        }).catch((error) => {
            console.error(error);
            toast.update(
                toastId,
                {
                    render: 'Houve um erro ao tentar fazer o download dos arquivos!',
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000
                }
            )
        }).finally(() => {
            setLoadingDownloads(false)
            setHasFileSelected(false)
        });
    }

    function selectFile(file) {
        const newFilesArray = files.map((fileAux) => {
            if (file.id === fileAux.id) {
                return {
                    ...fileAux,
                    checked: !fileAux.checked
                }
            }

            return fileAux;
        });

        setFiles([ ...newFilesArray ])
        setHasFileSelected(!!newFilesArray.find(file => file.checked))
    }

    return(
        <div className="files-container">
            <Header />
            <div className="files-menu-container">
                <Menu />
                <main>
                    <div className="files-info">
                        <div className="files-content">
                            <h1>Arquivos</h1>
                            <p>Bases que foram feitas upload no Access Review</p>
                        </div>
                        <div className="files-actions">
                            <Link className="button create-file-button" to="/uploads">
                                Novo
                            </Link>
                           {!loadingExport && <button className="button download-file-button" onClick={() => handleExport()}>
                                Exportar acessos
                            </button>}
                            {loadingExport && <button className="button disabled-button" disabled>
                                Carregando...
                            </button>}
                            <button className="button download-file-button"
                                  disabled={ !hasFileSelected || loadingDownloads}
                                  onClick={ handleAllDownloads }>
                                Baixar arquivos selecionados
                            </button>
                        </div>
                        <div className="filters">
                            <input
                                id="search-file-input"
                                placeholder="Digite o nome da base a ser buscada"
                                onKeyUp={(e) => setSearchFile(e.target.value)}
                            >
                            </input>
                        </div>
                    </div>
                    {loading && <AccessSkeleton />}
                    {!loading && <div className="files-lists">
                        <div className="files-header">
                            <p className="header-name">Arquivo</p>
                            <p className="header-type">Tipo</p>
                            <p className="header-created_at">Criado em</p>
                            <p className="header-hash">Hash</p>
                            <p className="header-link">Ação</p>
                        </div>
                        { files && files.map((file) => (
                            <div className="file" key={ file.id }>
                                <div className="file-name">
                                    <input type="checkbox"
                                           checked={ file.checked }
                                           onChange={ () => selectFile(file) }/>
                                    {file.name}
                                </div>
                                <p className="file-type">
                                    {file.type === 'accesses'?'Acessos':(file.type === 'output'? 'Exportado' : 'Funcionários')}
                                </p>
                                <p className="file-created_at">
                                    {file.created_at ? format(parseISO(file.created_at), 'dd/MM/yyyy HH:mm:ss'):'-'}
                                </p>
                                <p className="file-hash">
                                    {file.hash}
                                </p>
                                <p className="file-link">
                                    <FaFileDownload className="download" onClick={() => handleDownload(file.link, file.type)} />
                                </p>
                            </div>
                        ))}
                        { !files.length && <h3 className="no-files-alert">Nenhum arquivo encontrado!</h3> }
                    </div>}
                </main>
            </div>
        </div>
    )
}