import React, {useEffect, useState} from 'react';
import './styles.css';
import api from '../../services/api';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import AccessSkeleton from '../../components/AccessSkeleton';
import Fuse from "fuse.js";

export default function ManagersList(){
    let fuseInstance = null;
    const [managers, setManagers ] = useState([]);
    const [backupManagers, setBackupManagers] = useState({});
    const [loading, setLoading ] = useState(true);
    const [searchReviewer, setSearchReviewer ] = useState('');

    useEffect(() => {
        try {
            api.get(`acessos_gestores?email=${searchReviewer}`).then(response => {
                const accesses = response.data;
                setManagers(accesses);
                setBackupManagers(response.data);
                setLoading(false)               
            }).catch(err => console.log(err)); 
        } catch (error) {
            alert('Erro ao acessar.')
        }
    }, []);

    useEffect(() => {
        if (!backupManagers?.total_accesses) return;
        if (!searchReviewer) {
            return setManagers(backupManagers);
        }

        const fuseInstance = getFuseInstance();
        if (!fuseInstance) return;

        const searchResult = fuseInstance.search(searchReviewer);
        const total_accesses = searchResult.map((result) => {
            return {
                ...result.item
            }
        });

        setManagers({ ...backupManagers, total_accesses })
    }, [searchReviewer]);

    function getFuseInstance() {
        if (!backupManagers?.total_accesses) {
            console.warn('was not possible create Fuse instance because managers list has not arrived yet');
            return null;
        }

        if (!fuseInstance) {
            fuseInstance = new Fuse(
                backupManagers.total_accesses,
                {
                    shouldSort: true,
                    includeScore: true,
                    ignoreLocation: true,
                    threshold: 0.4,
                    keys: ['reviewer_mail']
                }
            );
        }

        return fuseInstance;
    }

    function pending_accesses(managers, manager){
        return managers.pending_accesses.find(m => (m.reviewer_mail === manager.reviewer_mail))?.total || 0
    }

    function get_apps(managers, manager){
        let array_apps=[]
        managers.review_logs.map(logs => {return (logs.user_email === manager.reviewer_mail)?array_apps.push(logs.app):''})
        return array_apps.join(', ')
    }    

    return(
        <div className="managers-list-container">
            <Header />
            <div className="managers-list-menu-container">
                <Menu />
                <main>
                    <div className="filters">
                        <input
                            type="text"
                            name="search-access"
                            placeholder="Pesquise um revisor"
                            onKeyUp={e => setSearchReviewer(e.target.value)} />
                    </div>
                    {loading && <AccessSkeleton />}
                    {!loading && <>
                        <div className="managers-header">
                            <p>Revisor</p>
                            <p>Acessos totais</p>
                            <p>Acessos pendentes</p>
                            <p>Acessos revisados</p>
                            <p>% de revisão</p>
                            <p>Apps</p>
                        </div>
                        {managers.total_accesses.map((manager, index) => (
                            <div className="access" key={manager.reviewer_mail}>
                                <p>{manager.reviewer_mail}</p>
                                <p>{manager.total}</p>
                                <p>{pending_accesses(managers, manager)}</p>
                                <p>{manager.total-pending_accesses(managers, manager)}</p>
                                <p>{((manager.total-pending_accesses(managers, manager))*100/manager.total).toFixed(1)}%</p>
                                <p>{get_apps(managers, manager)}</p>                                
                            </div>
                        ))}
                    
                    </>}      
                </main>
            </div>
        </div>
    )
}