import axios from 'axios';
import Cookies from 'js-cookie'
import { getApiUrl } from '../utils/utils';

const api = axios.create({
    baseURL: getApiUrl(),
    headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        Permission: `Bearer ${Cookies.get('permission')}`
    }
})

api.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        Cookies.remove('token')
        Cookies.remove('permission')
        alert('Sessão expirada! Faça o login novamente');
    }

    return Promise.reject(error);
})

export default api;