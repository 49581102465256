import React from 'react';
import './styles.css';
import { MdLink, MdCheckCircle, MdError } from 'react-icons/md'

export default function FileList({ files }){
    return(
       <ul className="file-list-container">
           <li>
               <p>Arquivo</p>
               <p>Upload</p>
               <p>Sucesso</p>
               <p>Erro</p>
           </li>
           {files.map(uploadedFile => (
               <li key={uploadedFile.id}>
                    <div className="file-list-file-info">
                        <div>
                            <strong>{uploadedFile.name}</strong>
                            <span>{uploadedFile.readableSize}</span>
                        </div>
                    </div>

                    <div>
                        {!uploadedFile.uploaded && !uploadedFile.error && (
                            <svg className="svg-loading" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="45"/>
                            </svg>
                        )}
                        
                        {uploadedFile.url && (
                            <a href={uploadedFile.url} target="_blank" rel="noopener noreferrer">
                                <MdLink styles={{ marginRight: 8}} size={24} color="#222" />
                            </a>
                        )}

                        {uploadedFile.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
                        {uploadedFile.error && <MdError size={24} color="#e57878" />}
                    </div>
                    {(uploadedFile.uploaded || uploadedFile.error) && <>
                        <div>{uploadedFile.success}</div>
                        <div>{uploadedFile.errors}</div>
                    </>}
                </li>
           ))}
       </ul>
    )
}