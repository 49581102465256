import React from 'react';
import './styles.css';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/logo.png'
import { FaSignOutAlt, FaQuestionCircle } from 'react-icons/fa';

export default function Header(){
    const history = useHistory();
    let development_env = false;

    function handleLogout() {
        localStorage.clear();
        history.push('/');
    }

    if(process.env.NODE_ENV === 'development'){
        development_env = true
    }else if(process.env.NODE_ENV === 'production' 
    && (window.location.href.includes('https://access-review-frontend-xr2fzomgbq-ue.a.run.app/') 
    || window.location.href.includes('https://access-review-stg.stone.com.br'))){
        development_env = true
    }

    return(
        <header className={development_env?'header-test':''}>
            <img src={logo} alt="Stone" />
            {development_env && <div className="test-title">
                <h1>Ambiente de testes</h1>
            </div>}
            <div className="header-actions">
                <a target="_blank" className="header-help" rel="noreferrer" href="https://stonepayments.atlassian.net/wiki/spaces/~275541436/pages/2517566348/Revis+o+de+acessos">
                <FaQuestionCircle />&nbsp;Ajuda</a>
                <button className="logout-button" onClick={() => handleLogout()}>
                    <FaSignOutAlt />
                </button>
            </div>
        </header>
    )
}