import React, { useState,useEffect } from 'react';
import './styles.css';
import Header from '../../components/Header';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import AccessSkeleton from '../../components/AccessSkeleton';
import { format, parseISO } from 'date-fns';

export default function ReviewLog(){
    const [loading, setLoading ] = useState(true);
    const [reviewLogs, setReviewLogs ] = useState([]);

    useEffect(() => {
        try {
            api.get(`logs_revisao`).then(response => {
                setReviewLogs(response.data)
                setLoading(false)
            }).catch(err => console.log(err));
        } catch (error) {
            if(error.response.status !== 401){
                if(error.response?.data?.error){
                    alert(error.response.data.error);
                }else{
                    alert('Erro ao acessar.')
                }
            }
        }
    }, []);

    return(
        <div className="reviw-log-container">
            <Header />
            <main>
                <div className="review-options">
                    <Link className="button review-options-review-logs-button" to="/periodos_revisao">
                        Períodos de revisão
                    </Link>
                    <Link className="button review-options-pending-button" to="/revisar">
                        Revisões Pendentes
                    </Link>
                    <Link className="button review-options-finished-button" to="/revisadas">
                        Revisões Finalizadas
                    </Link>
                </div>
                {loading && <AccessSkeleton />}
                {!loading && <div className="logs-container">
                <div className="log-header">
                    <p className="header-app">Aplicação</p>
                    <p className="header-accesses-amount">Revisões pendentes</p>
                    <p className="header-start-date">Data de início</p>
                    <p className="header-end-date">Data de término</p>
                </div>
                    {reviewLogs.map((log, index) => (
                        <div className="log" key={log.id}>
                            <p className="log-app">{log.app}</p>
                            <p className="log-accesses-amount">{log.accesses_amount}</p>
                            <p className="log-start-date">{log.start_date?format(parseISO(log.start_date), 'dd/MM/yyyy'):'Não iniciado'}</p>
                            <p className="log-end-date">{log.end_date?format(parseISO(log.end_date), 'dd/MM/yyyy'):'Em aberto'}</p>
                        </div>
                    ))}
                </div>}
            </main>
        </div>
    )
}