import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './pages/Login'
import Review from './pages/Review'
import FinishedReview from './pages/FinishedReview'
import ReviewLog from './pages/ReviewLog'
import Dashboard from './pages/Dashboard'
import AppsList from './pages/AppsList'
import ManagersList from './pages/ManagersList'
import AccessesList from './pages/AccessesList'
import UploadData from './pages/UploadData'
import ReviewPeriods from './pages/ReviewPeriods'
import ReviewPeriodsCreate from './pages/ReviewPeriods/create'
import Files from './pages/Files'
import Callback from './pages/Callback'
import { UserProvider } from './contexts/UserProvider';
import { isAuthorized } from './services/permission';

const PrivateRoute = ({ component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props => isAuthorized(rest.role)? ( <Component {...props} />) : (
            <Redirect to={{ pathname: "/", state: { from: props.location} }} />
        )}
    />
);

function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login} />

                <UserProvider>
                    <Route path="/callback" component={Callback} />

                    {/* Revisor */}
                    <PrivateRoute path="/revisar" component={Review} role="Revisor" />
                    <PrivateRoute path="/revisadas" component={FinishedReview}  role="Revisor"  />
                    <PrivateRoute path="/periodos_revisao" component={ReviewLog}  role="Revisor"  />

                    {/* Administrador */}
                    <PrivateRoute path="/dashboard" component={Dashboard}  role="Administrator"  />
                    <PrivateRoute path="/revisao_ferramentas" component={AppsList}  role="Administrator"  />
                    <PrivateRoute path="/revisao_gestores" component={ManagersList}  role="Administrator"  />
                    <PrivateRoute path="/revisao_acessos" component={AccessesList}  role="Administrator"  />
                    <PrivateRoute path="/uploads" component={UploadData}  role="Administrator"  />
                    <PrivateRoute path="/periodos" exact component={ReviewPeriods}  role="Administrator"  />
                    <PrivateRoute path="/periodos/create" component={ReviewPeriodsCreate}  role="Administrator"  />
                    <PrivateRoute path="/arquivos" component={Files}  role="Administrator"  />
                </UserProvider>
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;