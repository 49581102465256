import React, {useEffect, useState} from 'react';
import './styles.css';
import api from '../../services/api';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import AccessSkeleton from '../../components/AccessSkeleton';
import { format, parseISO } from 'date-fns';
import Pagination from '@material-ui/lab/Pagination';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Fuse from "fuse.js";

export default function AccessesList(){
    let fuseReviewerInstance = null;
    let fuseUserInstance = null;
    const [accesses, setAccesses ] = useState([]);
    const [backupAccesses, setBackupAccesses] = useState([]);
    const [searchReviewer, setSearchReviewer] = useState('');
    const [searchUser, setSearchUser ] = useState('');

    const [loading, setLoading ] = useState(true);
    const [page, setPage ] = useState(1);
    const [totalPages, setTotalPage ] = useState(1);
    const [toolOptions, setToolOptions ] = useState([]);
    const [roleOptions, setRoleOptions ] = useState([]);
    const [filteredTool, setFilteredTool ] = useState('');
    const [filteredRole, setFilteredRole ] = useState('');
    const [status, setStatus ] = useState('');
    const [lastModifiedBy, setLastModifiedBy ] = useState('');
    const [total, setTotal ] = useState('');
    const [orderBy, setOrderBy ] = useState('');
    const [orderType, setOrderType ] = useState('asc');
    const [orderAsc, setOrderAsc ] = useState(true);
    const limitPerPage = 500;

    function handleChange(e, value) {
        setPage(value);
    }

    async function handleOrder(collumn){
        setOrderBy(collumn)
        setOrderAsc(!orderAsc)

        if(!orderAsc){
            setOrderType('desc')
        } else{
            setOrderType('asc')
        }
    }

    useEffect(() => {
        api.get(
            `acessos?page=${page}&app_id=${filteredTool}&role_id=${filteredRole}&status=${status}&orderBy=${orderBy}&orderType=${orderType}&last_modified_by=${lastModifiedBy}`
        ).then((response) => {
            setAccesses(response.data.accesses);
            setBackupAccesses(response.data.accesses);
            setTotalPage(Math.ceil(response.data.count.count/limitPerPage))
            setTotal(response.data.count.count)
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            toast.error('Houve um erro inesperado, por favor tente mais tarde!');
        })
    }, [page, filteredTool, filteredRole, status, orderBy, orderType, lastModifiedBy])

    useEffect(() => {
        api.get(`ferramentas?current_user=false`).then(response => {
            setToolOptions(response.data)
        }).catch(err => console.log(err));
    }, [])

    useEffect(() => {
        api.get(`permissoes?filtered_app=${filteredTool}`).then(response => {
            setRoleOptions(response.data)
        }).catch(err => console.log(err));
    }, [toolOptions, filteredTool])

    useEffect(() => {
        if (!accesses) return;
        if (!searchReviewer) {
            return setAccesses(backupAccesses);
        }

        const fuseInstance = getFuseReviewerInstance();
        if (!fuseInstance) return;

        const searchResult = fuseInstance.search(searchReviewer);
        setAccesses(searchResult.map((result) => {
            return {
                ...result.item
            }
        }));
    }, [searchReviewer]);

    function getFuseReviewerInstance() {
        if (!backupAccesses) {
            console.warn('was not possible create Fuse instance because accesses list has not arrived yet');
            return null;
        }

        if (!fuseReviewerInstance) {
            fuseReviewerInstance = new Fuse(
                backupAccesses,
                {
                    shouldSort: true,
                    includeScore: true,
                    ignoreLocation: true,
                    threshold: 0.4,
                    keys: ['reviewer_mail']
                }
            );
        }

        return fuseReviewerInstance;
    }

    useEffect(() => {
        if (!accesses) return;
        if (!searchUser) {
            return setAccesses(backupAccesses);
        }

        const fuseInstance = getFuseUserInstance();
        if (!fuseInstance) return;

        const searchResult = fuseInstance.search(searchUser);
        setAccesses(searchResult.map((result) => {
            return {
                ...result.item
            }
        }));
    }, [searchUser]);

    function getFuseUserInstance() {
        if (!backupAccesses) {
            console.warn('was not possible create Fuse instance because accesses list has not arrived yet');
            return null;
        }

        if (!fuseUserInstance) {
            fuseUserInstance = new Fuse(
                backupAccesses,
                {
                    shouldSort: true,
                    includeScore: true,
                    ignoreLocation: true,
                    threshold: 0.6,
                    keys: ['user_mail']
                }
            );
        }

        return fuseUserInstance;
    }

    return(
        <div className="accesses-list-container">
            <Header />
            <div className="accesses-list-menu-container">
                <Menu />
                <main>
                    <div className="title">
                        <h1>Acessos</h1>
                        <div className="numbers">
                            <p>Total de acessos: <strong>{total}</strong></p>
                        </div>
                    </div>
                    <div className="filters">
                        <select name="filtered-tool" id="filtered-tool" onChange={e => setFilteredTool(e.target.value)}>
                            <option key='0' value=''>- Selecione uma ferramenta -</option>
                            {toolOptions.map(app => (
                                <option key={app.id} value={app.id}>{app.name}</option>
                            ))}
                        </select>
                        <select name="filtered-role" id="filtered-role" onChange={e => setFilteredRole(e.target.value)}>
                            <option key='0' value=''>- Selecione uma permissão -</option>
                            {roleOptions.map(role => (
                                <option key={role.id} value={role.id}>{role.name}</option>
                            ))}
                        </select>
                        <input
                            type="text"
                            name="search-access"
                            placeholder="Pesquise um revisor"
                            onKeyUp={ e => setSearchReviewer(e.target.value) } />
                        <input
                            type="text"
                            name="search-access"
                            placeholder="Pesquise um usuário"
                            onKeyUp={e => setSearchUser(e.target.value)} />
                        <select name="filtered-status" id="filtered-status" onChange={e => setStatus(e.target.value)}>
                            <option key='0' value=''>- Selecione um status -</option>
                            <option key='1' value='1'>Acessos marcados como excluir</option>
                            <option key='2' value='2'>Acessos revisados</option>
                            <option key='3' value='3'>Acessos com vínculo de funcionário</option>
                            <option key='4' value='4'>Acessos não revisados</option>
                        </select>
                        <select name="filtered-last-modified-by" id="filtered-last-modified-by" onChange={e => setLastModifiedBy(e.target.value)}>
                            <option key='0' value=''>- Selecione um modificador -</option>
                            <option key='1' value='revisor'>Revisor</option>
                            <option key='2' value='baserh'>Base RH</option>
                            <option key='3' value='acessodefault'>Acessos Default</option>
                        </select>
                    </div>
                    {loading && <AccessSkeleton />}
                    {!loading && <>
                        <div className="accesses-header">
                            <p>Id</p>
                            <p className="header-employee" onClick={e => handleOrder('user_name')}>
                                <span className="header-title">Colaborador</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-app" onClick={e => handleOrder('apps.name')}>
                                <span className="header-title">Ferramenta</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-role" onClick={e => handleOrder('accesses_roles.name')}>
                                <span className="header-title">Permissão</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-created" onClick={e => handleOrder('user_created_at')}>
                                <span className="header-title">Criado em</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-last-login" onClick={e => handleOrder('last_login')}>
                                <span className="header-title">Último login</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-revised-at" onClick={e => handleOrder('revised_at')}>
                                <span className="header-title">Revisado em</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p>Revisão</p>
                            <p>Modificado</p>
                        </div>
                        <div className="accesses-list">
                        {accesses.map((access, index) => (
                            <div className="access" key={access.id}>
                                <p className="access-id">{access.id}</p>
                                <div className="access-info">
                                    <h2 className="access-name">{access.user_name?access.user_name:'-'}</h2>
                                    {access.user_mail?.length > 1 && (<p>{access.user_mail}</p>)}
                                    {access.user_primary_key && access.user_primary_key !==access.user_mail && (<p>{access.user_primary_key}</p>)}
                                </div>
                                <p className="access-tool">{access.app}</p>
                                <p className="access-role">{access.role}</p>
                                <p className="access-created">{access.user_created_at?format(parseISO(access.user_created_at), 'dd/MM/yyyy'):'Não informado'}</p>
                                <p className="access-last-login">{access.last_login?format(parseISO(access.last_login), 'dd/MM/yyyy'):'Não informado'}</p>
                                <p className="access-revised-at">
                                    {access.revised_at? (
                                        <>
                                            {format(parseISO(access.revised_at), 'dd/MM/yyyy HH:mm')}<br></br><span className="access-reviewer">por {access.reviewer_mail}</span>
                                        </>
                                    ): <>
                                        -
                                        <br></br><span className="access-reviewer">por {access.reviewer_mail}</span>

                                        </>}
                                </p>
                                <div className="access-actions">
                                    {access.revised_at?<strong className="access-status">{access.is_valid?'Manter':'Excluir'}</strong>:'Pendente'}
                                    {access.justification_id && (
                                        <div className="access-justification"><strong>Justificativa:&nbsp;</strong>
                                            {access.justification_id === 1 && 'Não necessita mais do acesso para exercer suas atividades'}
                                            {access.justification_id === 2 && 'Profissional desligado/contrato encerrado'}
                                            {access.justification_id === 3 && 'Descontinuidade de serviço'}
                                        </div>
                                    )}
                                </div>
                                <p className="access-last-modified-by">{access.last_modified_by}</p>
                            </div>
                        ))}
                        </div>

                    </>}

                    <div className='footer'>
                        <Pagination count={totalPages} page={page} onChange={handleChange} color='standard' />
                    </div>
                </main>
            </div>
        </div>
    )
}