import React, {useEffect, useState} from 'react';
import './styles.css';
import api from '../../services/api';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

export default function Dashboard(){
    const [allAccesses, setAllAccesses ] = useState(0);
    const [completedReviews, setCompletedReviews ] = useState(0);
    const [validAccesses, setValidAccesses ] = useState(0);
    const [invalidAccesses, setInvalidAccesses ] = useState([]);

    useEffect(() => {
        async function loadAccesses(){
            await api.get('acessos_dashboard').then(response => {
                setAllAccesses(response.data.countTotalAccesses[0].count);
                setCompletedReviews(response.data.countRevisedlAccesses[0].count);
                setValidAccesses(response.data.countValidAccesses[0].count);
                setInvalidAccesses(response.data.countInvalidAccesses);
            }).catch(err => console.log(err))
        }

        try {
            loadAccesses();           
        } catch (error) {
            alert('Erro ao acessar.')
        }
    }, []);

    return(
        <div className="dashboard-container">
            <Header />
            <div className="dashboard-menu-container">
                <Menu />
                <main className="access-indicators">
                    <div className="access-indicators-title">
                        <h1><strong>{completedReviews}</strong>/{allAccesses}</h1>
                        <h3>Revisões realizadas</h3>
                    </div>
                    <div className="access-indicators-title">
                        <h1><strong>{(completedReviews*100/allAccesses).toFixed(2)}%</strong></h1>
                        <h3>Percentual revisão</h3>
                    </div>
                    <div className="access-indicators-title">
                        <h1><strong>{validAccesses}</strong></h1>
                        <h3>Acessos mantidos</h3>
                    </div>
                    {invalidAccesses.map(justification => (
                        <div key={justification.name} className="access-indicators-title">                        
                            <h1><strong>{justification.total}</strong></h1>
                            <h3>{justification.name}</h3>
                        </div>
                    ))}
                </main>
            </div>
        </div>
    )
}