import React, { createContext } from "react";
import Cookies from 'js-cookie'
import api from '../services/api';
import { useHistory } from 'react-router-dom';

export const UserContext = createContext({});

export function UserProvider({children}){
    const history = useHistory();
    
    async function signIn(token, permission, roles){
        try{

            if(!token || !permission){
                history.push('/');
            }

            Cookies.set('token', token, { expires: 3 })
            Cookies.set('permission', permission, { expires: 3 })

            api.defaults.headers['Authorization'] = `Bearer ${token}`;
            api.defaults.headers['Permission'] = `Bearer ${permission}`;
            
            if(roles && roles.includes('Administrator')){
                history.push('dashboard');
            }else{
                history.push('revisar');
            }

        } catch (error) {
            console.log(error);
        }
    }

    return(
        <UserContext.Provider value={{signIn}}>
            {children}
        </UserContext.Provider>
    )
}