import React from 'react';
import './styles.css';
import Dropzone from 'react-dropzone';

export default function Upload({ OnUpload }){

    function renderDragMessage(isDragActive,isDragReject){
        if(!isDragActive){
            return(
                <div className="upload-message upload-message-default">
                    Arraste arquivos aqui...
                </div>
            )
        }

        if(isDragReject){
            return(
                <div className="upload-message upload-message-error">
                    Arquivo não suportado
                </div>
            )
        }

        return(
            <div className="upload-message upload-message-success">
                Solte os arquivos aqui
            </div>
        )
    }

    return(
        <Dropzone accept='text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' onDropAccepted={OnUpload}>
            { ({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <div className=
                        { isDragActive && !isDragReject?'drop-container drag-active' : (isDragReject?'drop-container drag-reject': 'drop-container') }
                        {...getRootProps() } isDragActive={isDragActive} isDragReject={isDragReject}
                >
                    <input {...getInputProps()} />
                    {renderDragMessage(isDragActive,isDragReject)}
                </div>
            )              
            }
        </Dropzone>
    )
}