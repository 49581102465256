import React, {useState} from 'react';
import './styles.css';
import logo from '../../assets/logo.png';
import { getApiUrl } from '../../utils/utils';

export default function Login(){
  // TODO: SE FOR MUDAR URL MUDAR AQUI TBM
    const login_url = `${ getApiUrl() }/auth/autheticate`
    const [loading, setLoading] = useState(false);

    async function handleLogin(){
        setLoading(true)
    }

    return(
        <div className="login-container">
            <main>
                <img className="logo" src={logo} alt="Stone" />
                {loading && (
                    <button className="loading-button" disabled>
                        Carregando...
                    </button>
                )}
                {!loading && (
                    <a className="send-button" href={login_url} onClick={() => handleLogin()}>Login</a>
                )}
            </main>
        </div>
    )
}