import React, { useState,useEffect } from 'react';
import Cookies from 'js-cookie'
import { FaUndo, FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Pagination from '@material-ui/lab/Pagination';
import {toast} from "react-toastify";

import './styles.css';
import Header from '../../components/Header';
import api from '../../services/api';
import AccessSkeleton from '../../components/AccessSkeleton';

export default function Review(){
    const token = Cookies.get('token');
    const [accesses, setAccesses ] = useState([]);
    const [justification, setJustification ] = useState(1);
    const [showDelete, setShowDelete ] = useState([]);
    const [toolOptions, setToolOptions ] = useState([]);
    const [roleOptions, setRoleOptions ] = useState([]);
    const [filteredTool, setFilteredTool ] = useState('');
    const [filteredRole, setFilteredRole ] = useState('');
    const [checkedOptions, setCheckedOptions ] = useState([]);
    const [batchDelete, setBatchDelete ] = useState(false);
    const [loading, setLoading ] = useState(true);
    const [loadingBatch, setLoadingBatch ] = useState(false);
    const [page, setPage ] = useState(1);
    const [totalPages, setTotalPage ] = useState(1);
    const [totalPending, setTotalPending ] = useState(0);
    const [searchAreaManager, setSearchAreaManager ] = useState('');
    const [searchAccess, setSearchAccess ] = useState('');
    const [orderBy, setOrderBy ] = useState('');
    const [orderType, setOrderType ] = useState('asc');
    const [orderAsc, setOrderAsc ] = useState(true);
    const [accessesTotal, setAccessesTotal ] = useState([]);
    const [employees, setEmployees ] = useState([]);

    function handleChange(e, value) {
        setPage(value);
    }

    useEffect(() => {
        try {
            api.get(`revisar?showPending=1&page=${page}&app=${filteredTool}&role=${filteredRole}&search=${searchAccess}&orderBy=${orderBy}&orderType=${orderType}&searchAreaManager=${searchAreaManager}`).then(response => {
                setAccesses(response.data.accesses);
                setTotalPage(Math.ceil(response.data.count.count/500))
                setTotalPending(response.data.total.length)
                setAccessesTotal(response.data.total)
                setShowDelete(accesses.map(access => false));
                setLoading(false)
            }).catch(err => console.log(err));
        } catch (error) {
            console.log(error)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, page, filteredTool, filteredRole, searchAccess, orderBy, orderType, searchAreaManager]);

    useEffect(() => {
        let accesses_mails= accesses.map(access => { return access.user_mail });

        try {
            api.get(`funcionarios_acessos?accesses_mails=${accesses_mails}`).then(response => {
                setEmployees(response.data)
            }).catch(err => console.log(err));
        } catch (error) {
            console.log(error)
        }

    }, [accesses]);

    useEffect(() => {
        try {
            api.get(`ferramentas`).then(response => {
                setToolOptions(response.data)
            }).catch(err => console.log(err));
        } catch (error) {
            console.log(error)
        }

    }, [token])

    useEffect(() => {
        let apps_ids=[];

        if (!filteredTool) {
            apps_ids=toolOptions.map(app => {return app.id});
        }

        try {
            api.get(`permissoes?apps_ids=${apps_ids}&filtered_app=${filteredTool}&reviewer_accesses=true`).then(response => {
                setRoleOptions(response.data)
            }).catch(err => console.log(err));
        } catch (error) {
            console.log(error)
        }

    }, [toolOptions, filteredTool, accessesTotal])

    function handleEmployee(employee_id, type){
        if(employees.length > 0 && employee_id > 0){
            const data = employees.find(employee => employee.id === employee_id);

            if(type === 'area'){
                return data?.mindsight_area || '-'
            }else if(type === 'manager'){
                return data?.manager_name || '-'
            }else{
                return '-'
            }
        }

    }

    async function handleBatchUpdateAccess(ids, status, justification){
        setLoadingBatch(true);
        const idsLength = ids.length;
        const accesses_ids = ids.map(id => parseInt(id))

        const data = {
            is_valid: status,
            justification_id: status === false ? justification : null,
            revised_at: new Date(),
            accesses_ids
        }

        try {
            const response = await api.put('revisar_lote', data);

            if (response.status === 200) {
                toast.success('Acessos atualizados com sucesso!');
                setLoadingBatch(false);
                setBatchDelete(false);
                setCheckedOptions([]);
                setTotalPending(totalPending-idsLength)
                Array.from(document.querySelectorAll(".header-check"))
                    .forEach((checkbox) => {
                        checkbox.checked = false;
                    });
                if (idsLength === 500 && page < totalPages){
                    setPage(page+1);
                } else {
                    const newAccesses = accesses.filter(access => !accesses_ids.includes(access.id))
                    setAccesses(newAccesses);
                }
            } else {
                const errorMessage = response?.data?.error ?
                    response.data.error :
                    'Error inesperado, por favor tente novamente mais tarde!';
                throw new Error(errorMessage);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    function ShowDeleteContainer(id) {
        const newShowDelete = [...showDelete];
        newShowDelete[id] = !showDelete[id];
        setShowDelete(newShowDelete);
    }

    function ShowDeleteBatchContainer() {
        setBatchDelete(!batchDelete);
    }

    function handleCheck(e){
        if(e.target.checked){
            const addNewCheck = [...checkedOptions, e.target.name];
            setCheckedOptions(addNewCheck)
        }else{
            const removeNewCheck = checkedOptions.filter(option => (option !== e.target.name));
            setCheckedOptions(removeNewCheck);
        }
    }

    async function handleCheckAll(e){
        if(e.target.checked){
            const checkAll = await accesses.map(access => { return access.id.toString() });
            setCheckedOptions(checkAll);
            Array.from(document.querySelectorAll(".check-access"))
            .forEach((checkbox) => {
                checkbox.checked = true;
            });

        }else{
            setCheckedOptions([]);
            Array.from(document.querySelectorAll(".check-access"))
            .forEach((checkbox) => {
                checkbox.checked = false;
            });
        }
    }

    async function handleOrder(collumn){
        setOrderBy(collumn)
        setOrderAsc(!orderAsc)

        if(!orderAsc){
            setOrderType('desc')
        }else{
            setOrderType('asc')
        }
    }

    // async function handleDownload(){
    //     if(user.email){
    //         const response = await api.get('exporta_acessos_revisor', { responseType: 'blob' });
    
    //         const fileName = `${user.email}-output.xlsx`;
    
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.setAttribute('download', fileName);
    //         document.body.appendChild(a);
    //         a.click();
    //     }
    // }

    return(
        <div className="review-container">
            <Header />            
            <main>
                <div className="review-options">
                    <Link className="button review-options-review-logs-button" to="/periodos_revisao">
                        Períodos de revisão
                    </Link>
                    <Link className="button review-options-pending-button" to="/revisar">
                        {totalPending} Revisões Pendentes
                    </Link>
                    <Link className="button review-options-finished-button" to="/revisadas">
                        Revisões Finalizadas
                    </Link>
                    {/* <button className="button download" onClick={() => handleDownload()}>
                        Exportar acessos
                    </button> */}
                </div>
                {totalPending > 0 && <div className="review-filters">
                    <select name="filtered-tool" id="filtered-tool" onChange={e => setFilteredTool(e.target.value)}>
                        <option key='0' value=''>- Selecione uma ferramenta -</option>
                        {toolOptions.map(app => (
                            <option key={app.id} value={app.id}>{app.name}</option>
                        ))}
                    </select>
                    <select name="filtered-role" id="filtered-role" onChange={e => setFilteredRole(e.target.value)}>
                        <option key='0' value=''>- Selecione uma permissão -</option>
                        {roleOptions.map(role => (
                            <option key={role.id} value={role.id}>{role.name}</option>
                        ))}
                    </select>
                    <input type="text" name="search-access" placeholder="Pesquise um e-mail" onChange={e => setSearchAccess(e.target.value)} />
                    <input type="text" name="search-access" placeholder="Pesquise por área ou gestor" onChange={e => setSearchAreaManager(e.target.value)} />
                </div>}
                {checkedOptions && checkedOptions.length > 0 && !batchDelete && (<div className="batch-actions">
                    {loadingBatch && <button className="button" onClick={() => {}} disabled>
                        Carregando...
                    </button>}
                    {!loadingBatch && <><button className="confirm-button button" onClick={() => handleBatchUpdateAccess(checkedOptions, true, null)}>
                        Manter {checkedOptions.length} selecionados
                    </button>
                    <button className="delete-button button" onClick={() => ShowDeleteBatchContainer()}>
                        Excluir {checkedOptions.length} selecionados
                    </button></>}
                </div>)}
                {checkedOptions && checkedOptions.length > 0 && batchDelete && (<div className="batch-actions batch-actions-delete">
                    <div className="justification-select-input">
                            <label htmlFor="justification">Justificativa:</label>
                            <select name="justification" id="justification" onChange={e => setJustification(e.target.value)}>
                            <option key='1' value='1'>Não necessita mais do acesso para exercer suas atividades</option>
                            {/*<option key='2' value='2'>Profissional desligado/contrato encerrado</option>*/}
                            <option key='3' value='3'>Descontinuidade de serviço</option>
                        </select>
                    </div>
                    <div className="justification-actions">
                        {loadingBatch && <button className="button" onClick={() => {}} disabled>
                            Carregando...
                        </button>}
                        {!loadingBatch && <><button className="confirm-delete-button button" onClick={() => handleBatchUpdateAccess(checkedOptions, false, justification)}>
                            Confirmar {checkedOptions.length} exclusões
                        </button>
                        <button className="undo-delete-button button" onClick={() => ShowDeleteBatchContainer()}>
                            <FaUndo />
                        </button></>}
                    </div>
                </div>)}

                {loading && <AccessSkeleton />}

                {!loading &&<>

                    {totalPending === 0 && <div className="finished-review-container"><h1>Obrigado! Sua revisão foi concluída com sucesso :)</h1></div>}
                    
                    {totalPending > 0 && <div className="access-container">
                        <div className="access-header">
                            <input type="checkbox" className="header-check" onChange={e => handleCheckAll(e)} />
                            <p className="header-employee" onClick={e => handleOrder('user_name')}>
                                <span className="header-title">Colaborador</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-app" onClick={e => handleOrder('apps.name')}>
                                <span className="header-title">Ferramenta</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-role" onClick={e => handleOrder('accesses_roles.name')}>
                                <span className="header-title">Permissão</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-created" onClick={e => handleOrder('user_created_at')}>
                                <span className="header-title">Criado em</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-last-login" onClick={e => handleOrder('last_login')}>
                                <span className="header-title">Último login</span>
                                <span className="order">&nbsp;{orderAsc?<FaArrowDown />:<FaArrowUp />}</span>
                            </p>
                            <p className="header-area">Área</p>
                            <p className="header-manager">Gestor</p>
                            <p className="header-actions">Ações</p>
                        </div>
                        {accesses.length === 0 && <h2>0 resultados encontrados</h2>}
                        {accesses.map((access, index) => (
                            <div className="access" key={access.id}>
                                <input type="checkbox" className="check-access" name={access.id} onChange={e => handleCheck(e)} />
                                <div className="access-info">
                                    <h2 className="access-name">{access.user_name?access.user_name:'-'}</h2>
                                    {access.user_mail?.length > 1 && (<p>{access.user_mail}</p>)}
                                    {access.user_primary_key && access.user_primary_key !==access.user_mail && (<p>{access.user_primary_key}</p>)}
                                </div>
                                <p className="access-tool">{access.app}</p>
                                <p className="access-role">
                                    {access.role}
                                    {access.project_group && 
                                     <span className="project_group"><br></br>{access.project_group}</span>
                                    }
                                </p>
                                <p className="access-created">{access.user_created_at?format(parseISO(access.user_created_at), 'dd/MM/yyyy'):'Não informado'}</p>
                                <p className="access-last-login">{access.last_login?format(parseISO(access.last_login), 'dd/MM/yyyy'):'Não informado'}</p>
                                <p className="access-area">{handleEmployee(access.employee_id, 'area')}</p>
                                <p className="access-manager">{handleEmployee(access.employee_id, 'manager')}</p>
                                <div className="access-actions" style={{ display: !showDelete[access.id]?"block":"none" }}>
                                    <button className="confirm-button button" onClick={() => handleBatchUpdateAccess([access.id], true, null)}>
                                        Manter
                                    </button>
                                    <button className="delete-button button" onClick={() => ShowDeleteContainer(access.id)}>
                                        Excluir
                                    </button>
                                </div>
                                <div className="delete-container" style={{ display: showDelete[access.id]?"block":"none" }}>
                                    <div className="justification-select-input">
                                        <label htmlFor="justification">Justificativa:</label>
                                        <select name="justification" id="justification" onChange={e => setJustification(e.target.value)}>
                                            <option key='1' value='1'>Não necessita mais do acesso para exercer suas atividades</option>
                                            {/*<option key='2' value='2'>Profissional desligado/contrato encerrado</option>*/}
                                            <option key='3' value='3'>Descontinuidade de serviço</option>
                                        </select>
                                    </div>
                                    <div className="delete-actions">
                                        <button className="confirm-delete-button button" onClick={() => handleBatchUpdateAccess([access.id], false, justification)}>
                                            Confirmar exclusão
                                        </button>
                                        <button className="undo-delete-button button" onClick={() => ShowDeleteContainer(access.id)}>
                                            <FaUndo />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div>
                            <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
                        </div>
                    </div>}
                </>}
            </main>
        </div>
    )
}