import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './styles.css';

export default function AccessSkeleton({ cards = 4 }){
    const arr = Array.from({ length: cards }, (_, i) => i + 1);

    return (
        <SkeletonTheme color="#5b765f" highlightColor="#d5eace8a">
            <section className="access-skeleton-container">
                {arr.map((item) => (
                    <div className="access-skeleton" key={item}>
                        <Skeleton height={'100%'} width={'100%'} />
                    </div>
                ))}
            </section>
        </SkeletonTheme>
    );
}